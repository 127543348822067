<template>
	<page>
		<module>
			<c-form ref="form">
		        <c-form-item label="角色名称">
		          <c-input name="role_name" required></c-input>
		        </c-form-item>
						<c-form-item label="权限配置">
							<power-tree :power-list="powerList" style="width: 1200px;"></power-tree>
						</c-form-item>
		        <c-form-item label="备注">
		          <c-input type="textarea" name="remarks"></c-input>
		        </c-form-item>
			</c-form>
			<template slot="button">
            <c-button @click="submit" color="main" size="form">提交保存</c-button>
			</template>
		</module>
	</page>
</template>

<script>
	function renderPower(h, list, parent) {
    var arr = [], last = !list.find(item => item.children.length);
		list.map(item => {
			item.parent = parent;
			arr = arr.concat([
				<div class="power-item" style={`float: ${last ? 'left' : 'none'}`}>
					<c-checkbox value={item.chk != 0} on-click={$event => this.checkHandle(item, $event)}>{item.name}</c-checkbox>
					{
						item.children.length ? [
							<div class="power-list">
								{
                  renderPower.call(this, h, item.children, item)
								}
							</div>
						] : []
					}
				</div>
			]);
		});
		
		return arr;
	}
	
	const powerTree = {
		render(h) {
			return (
				<div class="power">
					{
						renderPower.call(this, h, this.powerList)
					}
				</div>
			)
		},
		
		props: {
			powerList: Array
		},
		
		methods: {
			checkHandle(power, state) {
				state = state ? 1 : 0;
				
				power.chk = state;
				!function poll(list) {
					list.forEach(item => {
						item.chk = state;
						poll(item.children);
					});
				}(power.children);
				
				!function poll(parent) {
					if (parent) {
						parent.chk = parent.children.find(item => !!item.chk) ? 1 : 0;
						poll(parent.parent);
					}
				}(power.parent);
			}
		}
	};
	
	export default {
		name: 'role_edit',
		
		components: {
			powerTree
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				powerList: []
			}
		},
		
		watch: {
			id() {
				this.update();
			}
		},
		
		mounted() {
			this.update();
		},
		
		methods: {
			update() {
				this.request({
					url: '/sys_role/detail',
					data: {
						role_id: this.id || 0
					},
					success: data => {
						if (this.id) {
							this.$refs.form.set(data.info);
						}
						this.powerList = data.role_menu;
					}
				});
			},
			
			submit() {
				this.$refs.form.submit({
					url: this.id ? '/sys_role/edit' : '/sys_role/add',
					rule: {
						role_name: '请填写角色名称'
					},
					dataFormatter: data => {
			              data.role_id = this.id;
			              data.role_menus = [];
			              !function poll(list) {
			                list.forEach(item => {
			                  if (item.chk) {
			                    data.role_menus.push({
			                      menu_id: item.id
			                    });
			                  }
			                  poll(item.children);
			                });
			              }(this.powerList);
			              return data;
					},
					success:(data,res) => {
						this.$message({
							type: 'success',
							message: '保存成功'
						});
						this.$bus.$emit('roleList.update', this.id ? undefined : 1);
						this.navigateTo('/role_list');
					},
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	>>> .power {
		max-height: 500px;
		border: 1px solid #ddd;
		overflow: auto;
			
		&-list {
			margin-left: 40px;
			overflow: hidden;
		}
		
		> &-item {
			margin-left: 20px;
			
			&:nth-last-child(1) {
				margin-bottom: 15px;
			}
		}
		
		&-item {
			margin-top: 15px;
			margin-right: 20px;
		}
	}
</style>