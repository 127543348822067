<template>
	<edit></edit>
</template>

<script>
	import edit from './role_edit.vue'
	
	export default {
		name: 'role_add',
		
		components: {
			edit
		}
	}
</script>